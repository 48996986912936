import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; 
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
//import { of } from 'rxjs/observable/of';
import { catchError, map } from 'rxjs/operators';
//import { User } from './models/user.model'
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthzUserInfo } from '../models/AuthzUserInfo.model'

//import { AuthenticationService } from 'app/shared/authentication/services/authentication.service';
import { Adal6Service } from 'adal-angular6';
import { NumberPipe } from '@progress/kendo-angular-intl';
//import { User } from './models/User.model';
//import { json } from 'd3';
import { LoggingService } from './logging.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService } from '@okta/okta-angular';
import { CookieService } from '../common/Cookies/cookieservice';

 
export class BaseService {
    options: Object;
    serviceBaseUrl: string;
    azureAdApiBaseUrl: string;
    private currentUser: AuthzUserInfo;
    private httpClient: HttpClient;
    public adalService: Adal6Service;
    public oktaAuthStateService: OktaAuthStateService;
    public oktaAuth:OktaAuth;
    private cookieService: CookieService;
 
    constructor(paramHttpClient: HttpClient = null, private loggingSvc: LoggingService) {
        this.loggingSvc.logDebug("Inside \"BaseService\" constructor");
        this.httpClient = paramHttpClient;
    }
 
    getAndObserve<T>(url: string): Observable<T> {
        this.loggingSvc.logDebug("Inside getAndObserve<T>(url: string): " + url);
 
        return this.httpClient.get<T>(url, { observe: 'response' })
            .pipe(catchError(this.handleError2(url, []))
            );
    }
 
    download<T>(url: string): Observable<T> {
        return this.httpClient.get(url, { observe: 'response' }).pipe(
            catchError(this.handleError2(url, []))
        );
    }
 
    getReqAndObserve<T>(url: string, _params: HttpParams): Observable<T> {
        this.loggingSvc.logDebug("Inside getAndObserve<T>(url: string): " + url);
 
        return this.httpClient.get<T>(url, { observe: 'response', params: _params })
            .pipe(catchError(this.handleError2(url, []))
            );
    }
 
    postAndObserve<T>(url: string, data: any): Observable<T> {
        this.loggingSvc.logDebug("Inside postAndObserve<T>(url: string, data: any): " + url);
 
        return this.httpClient.post<T>(url, data, { observe: 'response' })
            .pipe(catchError(this.handleError2(url, [])));
    }
 
    putAndObserve<T>(url: string, data: any): Observable<T> {

        this.loggingSvc.logDebug("Inside putAndObserve<T>(url: string, data: any): " + url);
        this.loggingSvc.logDebug(data + ": Data");
 
        return this.httpClient.put<T>(url, data, { observe: 'response' })
            .pipe(catchError(this.handleError2(url, [])));
    }
 
    deleteAndObserve<T>(url: string): Observable<T> {
       
        this.loggingSvc.logDebug("Inside deleteAndObserve<T>(url: string, data: any): " + url);
 
        return this.httpClient.delete<T>(url, { observe: 'response' })
            .pipe(catchError(this.handleError2(url, [])));
    }
 
    deleteAndObserveWithPayLoad<T>(url: string, data: any): Observable<T> {
       
        this.loggingSvc.logDebug("Inside deleteAndObserve<T>(url: string, data: any): " + url);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: data,
        };
        return this.httpClient.delete<T>(url, httpOptions)
            .pipe(catchError(this.handleError2(url, [])));
    }
 
    get<T>(url: string): Observable<T> {
        this.loggingSvc.logDebug("Inside get<T>(url: string): " + url);
        let reqHeaders: HttpHeaders; 
        if (environment.apiUrl.indexOf("localhost") === -1) {
            if (environment.IsOktaAuthentication === "true") {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${localStorage.getItem('wso2.idtoken')}`,
                    'x-authorization': `Bearer ${this.cookieService.getCookie('okta.idtoken')}`
                });
            }
             else {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${localStorage.getItem('wso2.idtoken')}`,
                    'x-authorization': `Bearer ${this.cookieService.getCookie('adal.idtoken')}`
                });
            }
        }
         else {
            if (environment.IsOktaAuthentication === "true") {
                const idToken = this.cookieService.getCookie('okta.idtoken');
                reqHeaders = new HttpHeaders({
                    Authorization: `Bearer ${idToken}`
                });
                console.log('ReqHeader from else ' + reqHeaders );
            }
             else {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${this.cookieService.getCookie('adal.idtoken')}`
                });
            }
        }
 
        const httpOptions = {
            headers: reqHeaders
        };
 
        return this.httpClient.get<T>(url, httpOptions)
            .pipe(catchError(this.handleError2(url, []))
            );
    }
 
    post<T>(url: string, data: any): Observable<T> {
        let reqHeaders: HttpHeaders;
        if (environment.IsOktaAuthentication === "true") {
            if (environment.apiUrl.indexOf("localhost") === -1) {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${localStorage.getItem('wso2.idtoken')}`,
                    'x-authorization': `Bearer ${this.cookieService.getCookie('okta.idtoken')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
                    'Access-Control-Allow-Headers':
                        '*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control',
                    'Content-Type': 'application/json; charset=utf-8'
                });
            }
             else {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${this.cookieService.getCookie('okta.idtoken')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
                    'Access-Control-Allow-Headers':
                        '*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control',
                    'Content-Type': 'application/json; charset=utf-8'
                });
            }
        }
         else {
            if (environment.apiUrl.indexOf("localhost") === -1) {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${localStorage.getItem('wso2.idtoken')}`,
                    'x-authorization': `Bearer ${this.cookieService.getCookie('adal.idtoken')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
                    'Access-Control-Allow-Headers':
                        '*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control',
                    'Content-Type': 'application/json; charset=utf-8'
                });
            }
             else {
                reqHeaders = new HttpHeaders({
                    'authorization': `Bearer ${this.cookieService.getCookie('adal.idtoken')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
                    'Access-Control-Allow-Headers':
                        '*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control',
                    'Content-Type': 'application/json; charset=utf-8'
                });
            }
        } 
        const httpOptions = {
            headers: reqHeaders
        }; 
        return this.httpClient.post<T>(url, data, httpOptions)
            .pipe(catchError(this.handleError2(url, []))
            );
    }

    put<T>(url: string, data: any): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
                'Access-Control-Allow-Headers':
                    '*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control',
                'Content-Type': 'application/json; charset=utf-8'
            })
        };

        return this.httpClient.put<T>(url, data, httpOptions)
            .pipe(catchError(this.handleError2()));
    }

    delete<T>(url): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
                'Access-Control-Allow-Headers':
                    '*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control'
            })
        };

        return this.httpClient.delete(url, httpOptions)
            .pipe(catchError(this.handleError2(url, [])));
    }
 
 
    protected handleError1(error: HttpErrorResponse) {
        return throwError(error.message);
    }
 
    protected handleError2<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<any> => {
            
            // TODO: send the error to remote logging infrastructure
            this.loggingSvc.logError(error); // log to console instead
            console.log(error.message);
            // TODO: better job of transforming error for user consumption
            this.loggingSvc.logDebug(`${operation} failed: ${error.message}`);
            return throwError(`Error: statusCode ${error.status} ${error.statusText}, ${error.error}, ${error.message}`);
             // Let the app keep running by returning an empty result.
            // return of(result as T);
        };
    } 
    
    public get CurrentUser(): AuthzUserInfo {
        if (localStorage.getItem("CurrentUser") !== undefined && localStorage.getItem("CurrentUser")) {
            this.currentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        }
        return this.currentUser;
    }
 
    public set CurrentUser(user: AuthzUserInfo) {
        this.currentUser = user;
        localStorage.setItem("CurrentUser", JSON.stringify(user));
    }
 
    public get CurrentUserLoginId(): string {
        return this.CurrentUser.userLoginId;
    }
 
    public get CurrentUserNumberId(): number {
        return this.CurrentUser.userNumberId;
    }
}
