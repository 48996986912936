import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root'
})
export class CookieService {
 
  constructor() { }
 
  // Set a cookie
  setCookie(name: string, value: string, days: number = 7): void {
    // Validate the cookie name and value for invalid characters (e.g., ';', '=', and spaces)
    if (!this.isValidCookieName(name)) {
      console.error('Invalid cookie name');
      return;
    }
    if (!this.isValidCookieValue(value)) {
      console.error('Invalid cookie value');
      return;
    }
 
    try {
      const expiresOnDate = new Date();
      expiresOnDate.setTime(expiresOnDate.getTime() + (days * 24 * 60 * 60 * 1000));  // Default expiration is 7 days
      const expiresString = `expires=${expiresOnDate.toUTCString()}`;
      document.cookie = `${name}=${value};${expiresString};path=/;Secure;SameSite=Strict`;
    } catch (error) {
      console.error('Error setting cookie:', error);
    }
  }
 
  // Get a cookie
  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
 
  // Validate cookie name (no spaces, equals, or semicolons)
  private isValidCookieName(name: string): boolean {
    const invalidChars = /[\s=;]/;
    return !invalidChars.test(name);
  }
 
  // Validate cookie value (optional, but can check length and invalid characters)
  private isValidCookieValue(value: string): boolean {
    const invalidChars = /[\r\n\t\f]/; // This can be expanded based on your needs
    return !invalidChars.test(value) && value.length <= 4096; // Ensure value does not exceed max cookie size
  }
}
