import { Injectable, Inject } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

// import { Observable } from 'rxjs/Observable';
import { environment } from "../../environments/environment";
import { Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorDialogService } from './error-dialog.service';
import { Adal6Service } from 'adal-angular6';
import { WSO2Service } from '../shared/wso2.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { CookieService } from '../common/Cookies/cookieservice';
 
declare var $: any;
 
@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
    private authToken;
    private wso2AuthToken;
    private oktaAuthToken;
    public pendingRequests: number;
    public showLoading: boolean;
    constructor(private router: Router,
        private errorDialogService: ErrorDialogService,
        private adalService: Adal6Service,
        private wso2Service: WSO2Service,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private cookieService: CookieService) {
        this.pendingRequests = 0;
    }
 
    intercept(req: HttpRequest<any>, next: HttpHandler):
     Observable<HttpEvent<any>> {
 
        this.wso2AuthToken = localStorage.getItem('wso2.idtoken');
        if (environment.IsOktaAuthentication === "true") {
            this.authToken = this.cookieService.getCookie('okta.idtoken');
        }
         else {
            this.authToken = this.cookieService.getCookie('adal.idtoken');
        }
 
        if (this.authToken == null && ((environment.apiUrl.indexOf("localhost") !== -1)
            || (environment.apiUrl.indexOf("localhost") === -1 && this.wso2AuthToken))) {
            this.errorDialogService.openDialog({ reason: '', status: 401 });
            return EMPTY;
        } 
        else if (!this.oktaAuth.authStateManager._authState.isAuthenticated) {
            this.authToken = this.cookieService.getCookie('okta.idtoken');
            const expiredTime = this.oktaAuth.tokenManager.getExpireTime(this.authToken);
            if (Date.now() / 1000 >= (expiredTime - 60)) {
                this.getRefreshToken();
                this.wso2Service.getWSO2RefreshToken();
                return EMPTY;
            }
        }
 
        if (!this.wso2AuthToken) {
            this.wso2Service.getWSO2RefreshToken();
        }
 
        let reqHeaders = {};
 
        if (req.url !== environment.wso2TokenURL && environment.apiUrl.indexOf("localhost") === -1) {
            reqHeaders = {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.wso2AuthToken}`,
                'X-Authorization': `Bearer ${this.authToken}`,
            }
        } 
        else if (environment.apiUrl.indexOf("localhost") !== -1) {
            reqHeaders = {
                Authorization: `Bearer ${this.authToken}`
            }
        }
 
        const reqCloned = req.clone({
            setHeaders: reqHeaders
        });
 
        if (reqCloned instanceof HttpRequest) {
            this.pendingRequests++;
            
            this.turnOnModal();
        }
 
        this.turnOffModal();
 
        return next.handle(reqCloned).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //this.paramLoggingSvc.logDebug('event--->>>', event);
                    //Here you will get response of request.
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.errorDialogService.openDialog(error);
                if (error.status === 401) {
                    this.getRefreshToken();
                    this.wso2Service.getWSO2RefreshToken();
                }
                
                return EMPTY;
            }));
    }
 
    private getRefreshToken() {
        if (environment.IsOktaAuthentication === "true") {
            const token = this.oktaAuth.getAccessToken();
            this.cookieService.setCookie('okta.idtoken', token);  // Store token in cookies
            return token;
        } 
        else {
            return this.adalService.acquireToken(environment.clientId).subscribe((token: string) => {
                this.cookieService.setCookie('adal.idtoken', token);  // Store token in cookies
                return token;
            });
        }
    }
 
    private turnOnModal() {
        if (!this.showLoading) {
            this.showLoading = true;
            $('body').spin("modal", "#FFFFFF", "rgba(51, 51, 51, 0.4)");
        }
        this.showLoading = true;
    }
 
    private turnOffModal() {
        this.pendingRequests--;
 
        if (this.pendingRequests <= 0) {
            if (this.showLoading) {
                $('body').spin("modal", "#FFFFFF", "rgba(51, 51, 51, 0.4)");
            }
            this.showLoading = false;
        }
    }
}

