import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SanitizationService {
  constructor(private sanitizer: DomSanitizer) {}

  sanitizeInput(input: string): string {
    // Treat input as untrusted and encode it
    if (!input) return '';
    // Perform input sanitization
    const sanitizedInput = input.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return sanitizedInput;
  }

  sanitizeHtml(input: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(input);
  }
}